.jga-card-vacancy{
	@extend .position-relative;
	background: $jga-grey;
	border-top: 5px solid $jga-green;
	padding-top: 66.66%;
	transition: all 0.35s ease-in-out;
	
	&:hover{
		background: lighten($jga-grey, 5);
	}
	
	.jga-card-inner{
		@extend .d-flex;
		@extend .flex-column;
		@extend .h-100;
		@extend .justify-content-between;
		@extend .position-absolute;
		color: $jga-light-grey;
		left: 0;
		padding: 30px;
		top: 0;
		
		h3{
			@extend .mb-2;
			@extend .text-white;
		}
		
		p{
			@extend .m-0;
			color: $jga-light-grey;
		}
		
		span{
			color: $jga-green;
		}
	}
}

.jga-wrapper-vacancies-single{
	
	.container{
		max-width: 830px;
	}
	
	p{
		&:first-of-type{
			font-size: 1.5rem;
			color: $black;
		}
	}
	
	p, ul{
		&:last-child{
			@extend .mb-0;
		}
	}
	
	.hvr-underline-from-left{
		vertical-align: top;
	}
}