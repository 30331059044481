//General Wrapper

.jga-wrapper{
	padding: $jga-wrapper-padding;
}

//Page Wrapper

.jga-wrapper-page .container{
	max-width: 1000px;
}

//Grey Wrapper

.jga-wrapper-grey{
	background: $ghost-grey;
}

//Grid Wrapper

.jga-wrapper-grid{
	background: $ghost-grey;
	padding: 15px 30px;
}

.tell-us-about-your-project{
	.jga-wrapper{
		.container{
			max-width: 680px;
		}
	}
}