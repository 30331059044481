//Page Header Green

.jga-page-header-green{
	height: 30vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background: $jga-green;
}

.jga-page-header-green h1{
	color: $white;
	margin: 0;
}

//Page Introduction

.jga-wrapper-page-introduction{
	
}

.jga-page-introduction-image{
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.jga-page-introduction-content{
	background: $jga-green;
	display: flex;
	justify-content:center;
	align-items: center;	
}

.jga-page-introduction-content div{
	padding: 60px 90px;
}

.jga-page-introduction-content h1{
	color: $white;
}

.jga-page-introduction-content h1 + p{
	font-family: "Gotham A", "Gotham B";
	font-style: normal;
	font-weight: 400;
	font-size: 1.25rem;
	line-height: 1.25;
	margin: 0 0 1.5rem;
}

.jga-page-introduction-content p{
	color: $white;
}

.jga-page-introduction-content a{
	color: $white;
}

.jga-page-introduction-content .hvr-underline-from-left:before{
	background: $white;
}

.jga-page-introduction-content ul{
	color: $white;
	list-style: none;
	margin: 0;
	padding: 0;
}

.jga-page-introduction-content strong{
	color: $white;
}

//Page Section

.jga-page-section-title h2{
	display: inline-block;
	border-bottom: 2px solid $jga-green;
	padding: 0 0 1rem;
}

.jga-page-section-content{
	padding: 0 0 0 15px;
}

.jga-page-section-content p:first-child{
	font-family: "Gotham A", "Gotham B";
	font-style: normal;
	font-weight: 400;
	font-size: 1.25rem;
	line-height: 1.25;
	color: $black;
}