//Solutions

.jga-solutions-introduction{
	margin: 0 0 52.5px;
	padding: 0 0 54px;
	border-bottom: 1px solid $jga-border;
}

.jga-solutions-single{
	margin: 0 0 60px;
}

.jga-solutions-single:last-child{
	margin: 0;
}

.jga-solutions-single-image{
	text-align: center;
}

.jga-solutions-single-image img{
	max-height: 150px;
	display: inline-block;
}

.jga-solutions-single-content{
	padding: 0 0 0 15px;
}

.jga-solutions-single-content h2{
	font-size: 1.75rem;
}



