//General

html{
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body, p{
	color: $grey;
	font-family: 'Overpass', sans-serif;
}

a{
	color: $jga-green;
	transition: $jga-transition;
}

a:hover{
	color: $jga-green;
	text-decoration: none;
	transition: $jga-transition;
}

h1, h2, h3, h4, h5, h6{
	font-family: "Gotham A", "Gotham B";
	font-style: normal;
	font-weight: 400;
	color: $black;
	margin: 0 0 1rem;
	line-height: 1.25;
}

h1{
	font-size: 3rem;
}

h2{
	font-size: 2.25rem;
}

h3{
	font-size: 1.75rem;
}

h4{
	font-size: 1rem;
}

.page p:last-child, .single p:last-child{
	margin: 0;
}

strong{
	color: $black;
}

img{
	width: 100%;
	height: auto;
}

ol{
	list-style-position: inside;
	padding-left: 0;
}

//Columns

.jga-col{
	margin: 15px 0;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

//Floats

.jga-left{
	text-align: left;
}

.jga-right{
	text-align: right;
}

.jga-center{
	text-align: center;
}

//Hover

.hvr-underline-from-left:before{
	background: $jga-green;
	height: 2px;
}

//WOW

.wow{
	visibility: hidden;
}

//Placeholder

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $jga-light-grey;
}
::-moz-placeholder { /* Firefox 19+ */
  color: $jga-light-grey;
}
:-ms-input-placeholder { /* IE 10+ */
  color: $jga-light-grey;
}
:-moz-placeholder { /* Firefox 18- */
  color: $jga-light-grey;
}