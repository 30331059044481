.jga-button{
	border: 2px solid $white;
	padding: 1rem 2rem;
	color: $white;
	background: none;
	display: inline-block;
	text-transform: uppercase;
	font-family: 'Overpass', sans-serif;
	font-weight: 700;
}

.jga-button:hover{
	background: $white;
	color: $jga-green;
}