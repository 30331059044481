//Cards

.jga-post-card{
	-webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.25);
	-moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.25);
	box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.25);
}

.jga-post-card-image a{
	display: block;
	transition: all ease 0.25s;
}

.jga-post-card-image a:hover{
	opacity: 0.95;
	transition: all ease 0.25s;
}

.jga-post-card-preview{
	padding: 25px;
	background: $jga-grey;
	border-top: 5px solid $jga-green;
}

.jga-post-card-preview time{
	display: block;
	margin: 0 0 0.4rem;
	color: $jga-light-grey;
	font-size: 12px;
	text-transform: uppercase;
}

.jga-post-card-work-preview span{
	display: block;
	margin: 0 0 0.25rem;
	color: $jga-light-grey;
	font-size: 12px;
	text-transform: uppercase;
}

.jga-post-card-preview h3{
	min-height: 60px;
	font-size: 1.25rem;
}

.jga-post-card-work-preview h3{
	min-height: inherit;
}

.jga-post-card-preview h3 a{
	color: $white;
}

.jga-post-card-preview h3 a:hover{
	color: #ddd;
}

.jga-post-card-preview a:hover{
	color: $jga-green;
}

//Post Nav

//Work Nav

.jga-post-footer{
	background: $ghost-grey;
	text-align: center;
}

.jga-post-footer h4{
	border: none;
	margin: 0 0 0.5rem;
	padding: 0;
}

.jga-post-footer-share{
	padding: 40px 0;
	border-bottom: 1px solid $jga-input-border;
}

.jga-post-footer-back{
	padding: 40px 0;
}