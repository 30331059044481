// Grid settings
$main-sm-columns:       	12;
$sidebar-sm-columns:    	4;

// Colours
$jga-green:					#89c9c4;
$jga-green-hover:			#a7d7d3;
$jga-grey:					#52525a;
$jga-light-grey:			#a9a4a8;
$jga-input-border:			#d5d5d5;
$black:						#111111;
$grey:						#666666;
$ghost-grey:				#f5f5f5;
$white:						#ffffff;

//Borders
$jga-border:				#e5e5e5 solid 1px;

//Transitions
$jga-transition:			all ease 0.25s;

//Padding
$jga-wrapper-padding:		80px 0 90px;

//Hamburgers
$hamburger-padding-x:		0;
$hamburger-padding-y:		0;
$hamburger-layer-width:		15px;
$hamburger-layer-height:	2px;
$hamburger-layer-spacing:	3px;
$hamburger-layer-color:		$jga-green;
