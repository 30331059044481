#iubenda_policy.iubenda_fluid_policy .iub_container{
	margin: 0 !important;
}

#iubenda_policy .iub_content{
	padding: 0 !important;
}

#iubenda_policy .iub_content .iub_footer{
	padding: 24px 0 0 !important;
}

.iub_content{
	font-family: 'Overpass', sans-serif !important;
}

.iub_content h1, .iub_content h2, .iub_content h3, .iub_content h4, .iub_content h5{
	font-family: "Gotham A", "Gotham B" !important;
	font-style: normal;
	font-weight: 400;
	font-variant: normal !important;
}

.iub_content a{
	color: $jga-green !important;
}