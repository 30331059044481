//People

.jga-people-introduction{
	margin: 0 0 60px;
	padding: 0 0 54px;
	border-bottom: 1px solid $jga-border;
}

.jga-people-single{
	margin: 0 0 60px;
}

.jga-people-single:last-child{
	margin: 0;
}

.jga-people-single-content{
	padding: 30px 0 0 15px;
}

.jga-people-single-content h3{
	margin: 0 0 0.25rem;
}

.jga-people-single-content h4{
	margin: 0 0 1rem;
	color: $jga-green;
}

.jga-people-single-content ul{
	list-style: none;
	padding: 0;
	margin: 0;
}

.jga-people-single-content ul li{
	display: inline-block;
	border-right: 1px solid #cccccc;
	padding: 0 10px 0 0;
	margin: 0 5px 0 0;
}

.jga-people-single-content ul li:last-child{
	border: none;
	padding: 0;
	margin: 0;
}