//Carousel

.jga-project-carousel div {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.jga-project-carousel-card{
	background: rgba(0,0,0,0.65);
	border-top: 5px solid $jga-green;
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 30px;
	margin: 30px;
	min-width: 25%;
}

.jga-project-carousel-card h2{
	margin: 0;
}

.jga-project-carousel-card h2 a{
	color: $white;
}

.jga-project-carousel-card p{
	margin: 0;
	color: $jga-light-grey;
	font-size: 12px;
	text-transform: uppercase;
}

.jga-project-carousel .owl-dots{
	position: absolute;
	bottom: 0;
	right: 0;
}

.jga-project-carousel .owl-nav.disabled+.owl-dots{
	margin: 30px;
	font-size: 0;
}

.owl-theme .owl-dots .owl-dot{
	font-size: 0;
	outline: none;
}

.owl-carousel .owl-item img{
	min-height: 260px;
	max-height: 900px;
}

.jga-project-carousel .owl-dots .owl-dot span{
	border-radius: 0;
	background: $ghost-grey;
	width: 15px;
	height: 15px;
	transition: $jga-transition;
	margin: 0 0 0 5px;
	display: inline-block;
}

.jga-project-carousel .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
	background: $jga-green;
	opacity: 1;
	transition: $jga-transition;
}

//Services

.jga-wrapper-home-services-border{
	background: #d0e9e7;
	background: -moz-linear-gradient(left, #d0e9e7 0%, #89c9c4 51%, #d0e9e7 100%);
	background: -webkit-linear-gradient(left, #d0e9e7 0%,#89c9c4 51%,#d0e9e7 100%);
	background: linear-gradient(to right, #d0e9e7 0%,#89c9c4 51%,#d0e9e7 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d0e9e7', endColorstr='#d0e9e7',GradientType=1 );
	height: 5px;
}

.jga-wrapper-home-services{
	background: #7d7d83;
	background: -moz-radial-gradient(center, ellipse cover, #7d7d83 0%, #52525a 100%);
	background: -webkit-radial-gradient(center, ellipse cover, #7d7d83 0%,#52525a 100%);
	background: radial-gradient(ellipse at center, #7d7d83 0%,#52525a 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7d7d83', endColorstr='#52525a',GradientType=1 );
}

.jga-wrapper-home-services img{
	max-height: 150px;
	display: inline-block;
}

.jga-wrapper-home-services h3, .jga-wrapper-home-services p{
	color: $white;
}

.jga-wrapper-home-services h3{
	margin: 1.5rem 0 1rem;
}

.jga-wrapper-home-services .jga-button{
	border-color: $jga-green;
	color: $jga-green;
	cursor: pointer;
	transition: $jga-transition;
	display: block;
	margin: 30px 0 0;
}

.jga-wrapper-home-services .jga-button:hover{
	background: $jga-green;
	color: $white;
	transition: $jga-transition;
}

//Studio

.jga-home-studio-image{
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-attachment: inherit;
}

.jga-home-studio-content{
	background: $jga-green;
	display: flex;
	justify-content:center;
	align-items: center;	
}

.jga-home-studio-content div{
	padding: 90px;
}

.jga-home-studio-content h2{
	color: $white;
}

.jga-home-studio-content p{
	color: $white;
	margin: 0 0 1.5rem;
}