//Footer

.jga-footer-top{
	padding: 60px 0;
	background: $jga-green;
	color: $white;
}

.jga-footer-bottom{
	padding: 60px 0;
	background: $jga-grey;
	color: $jga-light-grey;
}

.jga-footer-bottom a{
	color: $jga-green;
}

//Footer CTA

.jga-footer-cta{
	display: flex;
	align-items: center;
}

.jga-footer-cta p{
	font-family: "Gotham A", "Gotham B";
	font-style: normal;
	font-weight: 400;
	font-size: 1.25rem;
	color: $white;
	margin: 0;
	line-height: 1.2;
}

.jga-footer-cta strong{
	font-family: "Gotham A", "Gotham B";
	font-style: normal;
	font-weight: 700;
	color: $white;
	text-transform: uppercase;
}

//Footer Contact

.jga-footer-bottom-contact{
	border-bottom: 1px solid $jga-light-grey;
	padding: 0 0 30px;
	margin: 0 0 30px;
}

.jga-footer-contact-details{
	list-style: none;
	margin: 0;
	font-size: 0;
	color: $white;
	padding: 0;
}

.jga-footer-contact-details li{
	font-size: 1rem;
	display: inline-block;
	border-right: 1px solid $jga-light-grey;
	padding: 5px 10px 0 0;
	margin: 0 10px 0 0;
	height: 32px;
}

.jga-footer-contact-details li:last-child{
	margin: 0;
	padding: 0;
	border: none;
}

.jga-footer-contact-details li strong{
	color: $white;
}

//Footer Social

.jga-footer-contact-social{
	list-style: none;
	margin: 0;
	padding: 0;
	font-size: 0;
}

.jga-footer-contact-social li{
	display: inline-block;
	margin: 0 5px;
}

.jga-footer-contact-social li:first-child{
	margin-left: 0;
}

.jga-footer-contact-social li:last-child{
	margin-right: 0;
}

.jga-footer-contact-social li a{
	border: 2px solid $white;
	color: $white;
	border-radius: 50%;
	width: 32px;
	height: 32px;
	display: block;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1rem;
}

.jga-footer-contact-social li a:hover{
	color: $jga-grey;
	background: $white;
}