//Work Header

#jga-work-header{
	height: calc(100vh - 87px);
	min-height: 400px;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-attachment: fixed;
	text-align: center;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	
}

#jga-work-header h1{
	color: $white;
	margin: 0;
}

/*#jga-work-header-content{
	position: relative;
	top: 48.5%;
	transform: translateY(-48.5%);
	text-align: center;	
}*/

//Animated Mouse

@-webkit-keyframes ani-mouse {
	0% {
	opacity: 1;
	top: 29%;
	}
	15% {
	opacity: 1;
	top: 50%;
	}
	50% {
	opacity: 0;
	top: 50%;
	}
	100% {
	opacity: 0;
	top: 29%;
	}
}
@-moz-keyframes ani-mouse {
	0% {
	opacity: 1;
	top: 29%;
	}
	15% {
	opacity: 1;
	top: 50%;
	}
	50% {
	opacity: 0;
	top: 50%;
	}
	100% {
	opacity: 0;
	top: 29%;
	}
}
@keyframes ani-mouse {
	0% {
	opacity: 1;
	top: 29%;
	}
	15% {
	opacity: 1;
	top: 50%;
	}
	50% {
	opacity: 0;
	top: 50%;
	}
	100% {
	opacity: 0;
	top: 29%;
	}
}
 .scroll-btn {
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	text-align: center;
}
.scroll-btn > * {
	display: inline-block;
	line-height: 18px;
	font-size: 13px;
	font-weight: normal;
	color: #7f8c8d;
	color: #ffffff;
	font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
	letter-spacing: 2px;
}
.scroll-btn > *:hover,
.scroll-btn > *:focus,
.scroll-btn > *.active {
	color: #ffffff;
}
.scroll-btn > *:hover,
.scroll-btn > *:focus,
.scroll-btn > *:active,
.scroll-btn > *.active {
	opacity: 1;
	filter: alpha(opacity=80);
}
.scroll-btn .mouse {
	position: relative;
	display: block;
	width: 35px;
	height: 55px;
	margin: 0 auto 15px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	border: 3px solid white;
	border-radius: 23px;
}
.scroll-btn .mouse > * {
	position: absolute;
	display: block;
	top: 29%;
	left: 50%;
	width: 8px;
	height: 8px;
	margin: -4px 0 0 -4px;
	background: white;
	border-radius: 50%;
	-webkit-animation: ani-mouse 2.5s linear infinite;
	-moz-animation: ani-mouse 2.5s linear infinite;
	animation: ani-mouse 2.5s linear infinite;
}

.scroll-btn-text{
	font-family: "Gotham A", "Gotham B";
	font-style: normal;
	font-weight: 400;
	text-transform: uppercase;
	margin: 0 0 30px;
}

//Work Content

.jga-work-details{
	margin: 0;
	padding: 0;
	list-style: none;
}

//Work Video

.jga-work-video{
	margin: 60px 0 0;
}

.embed-container { 
	position: relative; 
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;
	height: auto;
} 

.embed-container iframe,
.embed-container object,
.embed-container embed { 
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

//Work Gallery

#jga-work-gallery{
	position: relative;
}

.jga-work-gallery-image a{
	display: block;
}

.jga-work-gallery-overlay{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	height: 100%;
	width: 100%;
	opacity: 0;
	transition: .5s ease;
	background: rgba(0,0,0,0.7);
	text-align: center;
	color: $white;
	font-size: 36px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.jga-work-gallery-image a:hover .jga-work-gallery-overlay {
	opacity: 1;
}

.jga-work-gallery-carousel .owl-nav{
	border-top: 1px solid $white;
}

.jga-work-gallery-carousel .owl-nav button.owl-prev, .jga-work-gallery-carousel .owl-nav button.owl-next{
	width: 50%;
	display: inline-block;
	text-align: center;
	background: $jga-green;
	color: $white;
	height: 64px;
	line-height: 64px;
	transition: opacity 0.5s ease;
	outline: none;
	font-size: 24px;
}

.jga-work-gallery-carousel .owl-nav button.owl-prev:hover, .jga-work-gallery-carousel .owl-nav button.owl-next:hover{
	opacity: 0.8;
	transition: opacity 0.5s ease;
}

.jga-work-gallery-carousel .owl-nav button.owl-prev{
	border-right: 1px solid $white;
}

//Lightbox

.lb-data .lb-details{
	display: none;
}

//Work Testimonial

#jga-work-testimonial{
	text-align: center;
}

#jga-work-testimonial p:last-child{
	text-transform: uppercase;
	line-height: 1.2rem;
}

#jga-work-testimonial span{
	color: $jga-green;
}