//News Single

.jga-news-single-header{
	background-position: center;
	background-size: cover;
	position: relative;
}

.jga-news-single-header img{
    width: 100%;
    height: auto;
    max-height: 60vh;
    min-height: 260px;
}

.jga-news-single-header-content{
	width: 100%;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: center;
}

.jga-news-single-header-content h1{
	color: $white;
	margin: 0 auto 1rem;
	max-width: 1000px;
}

.jga-news-single-header-content time{
	color: $white;
}

.jga-wrapper-news-single .container{
	max-width: 1000px;
}

.jga-wrapper-news-single p:first-child{
	font-size: 1.5rem;
	color: $black;
}

.jga-wrapper-news-single h2{
	margin: 1.75rem 0 1rem;
	font-size: 1.75rem;	
}

.jga-wrapper-news-single h3{
	margin: 1.75rem 0 1rem;
	font-size: 1.25rem;
}

.jga-wrapper-news-single a{
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px transparent;
	position: relative;
	overflow: hidden;	
}

.jga-wrapper-news-single a:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 100%;
  bottom: 0;
  background: $jga-green;
  height: 2px;
  -webkit-transition-property: right;
  transition-property: right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.jga-wrapper-news-single a:hover:before, .jga-wrapper-news-single a:focus:before, .jga-wrapper-news-single a:active:before {
  right: 0;
}