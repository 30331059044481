// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

// Forms

.jga-form-introduction{
	margin: 0 0 35px;
	padding: 0 0 40px;
	border-bottom: 1px solid $jga-border;	
}

.jga-form{
	
	label{
		color: $black;
		font-family: "Gotham A", "Gotham B";
		font-style: normal;
		font-weight: 400;
		line-height: 1;
		margin: 0 0 0.5rem;
	}
	
	.wpcf7-form-control-wrap{
		display: block;
		margin: 0 0 1.25rem;
	}
	
	.codedropz-upload-container{
		padding: 18px 20px 15px 20px;
	}
	
	.codedropz-upload-inner{
		h3{
			font-family: 'Overpass', sans-serif;
			font-size: 1rem;
			margin: 0 0 0.125rem;
		}
	}
	
	p{
		font-size: 0.75rem;
		line-height: 1.3;
		
		&:last-of-type{
			margin: 0 0 1.5rem;
		}
	}
	
	.jga-button{
		border-color: $jga-green;
		color: $jga-green;
		cursor: pointer;
		transition: $jga-transition;
		
		&:hover{
			background: $jga-green;
			color: $white;
			transition: $jga-transition;			
		}
	}
	
	input[readonly]{
		background: $white;
		color: #495057!important;
	}
}