@media only screen and (max-width: 1300px){
	.jga-home-studio-content div, .jga-page-introduction-content div{
		padding: 60px;
	}
}

//max-width 991px

@media only screen and (max-width: 991px){
	
	//Fonts
	
	h1{
		font-size: 2.5rem;
	}

	h2{
		font-size: 2rem;
	}

	h3{
		font-size: 1.5rem;
	}
	
	.jga-page-section-title h2{
		margin: 0 0 30px;
	}
	
	//Floats
	
	.jga-right{
		text-align: left;
	}
	
	//Wrappers
	
	.jga-wrapper{
		padding: 60px 0;
	}
	
	.jga-wrapper-grid{
		padding: 15px;
	}
	
	//Container
	
	.container{
		max-width: 100%;
	}
	
	//Header
	
	header{
		padding: 20px 15px;
	}
	
	//Content
	
	.jga-page-section-content, .jga-people-single-content, .jga-solutions-single-content{
		padding: 0;
	}
	
	.jga-page-introduction-content div, .jga-home-studio-content div{
		padding: 60px 15px;
	}
	
	.jga-people-single img{
		margin: 0 0 24px;
	}
	
	.jga-work-video{
		margin: 40px 0 0;
	}
	
	.jga-solutions-single-content{
		text-align: center;
	}
	
	.jga-solutions-single-image{
		margin: 0 0 1.5rem;
	}
	
	.jga-solutions-single-content h2{
		font-size: 1.5rem;
	}
	
	.jga-contact-map{
		height: auto;
		padding-bottom: 66.6%;
	}
	
	//Home
	
	.jga-project-carousel-card, .jga-project-carousel .owl-nav.disabled+.owl-dots{
		margin: 15px;
	}
	
	//Footer
	
	.jga-footer-cta, .jga-footer-contact-details{
		margin: 0 0 20px;
	}	
}

//min-width 769px

@media (min-width: 769px){
	
	//Header
	
	.jga-mobile-nav{
		display: none;
	}
	
	.nav-collapse{
		display: none !important;
	}
	
	#jga-nav-toggle{
		display: none;
	}
}

//max-width 768px

@media (max-width: 768px){
	
	//Header
	
	nav{
		display: none;
	}
	
	.nav-collapse{
		background: $jga-grey;
		text-align: center;
	}
	
	.nav-collapse li{
		border-top: 1px solid $jga-light-grey;
	}
	
	.nav-collapse li a{
		display: block;
		padding: 7.5px 0;
		color: $jga-light-grey;
	}
	
	.nav-collapse li a:hover{
		color: $jga-green;
	}
	
	.nav-collapse .active a{
		color: $jga-green;
	}
	
	#jga-nav-toggle{
		outline: none;
		color: $jga-green;
		margin: 13px 0 0;
	}
	
	#jga-nav-toggle:hover{
		opacity: 1;
	}
	
	#jga-work-header{
		background-attachment: inherit;
	}
	
	.jga-wrapper-home-services img{
		max-height: 100px;
	}
	
	.jga-solutions-single-image img{
		max-height: 100px;
	}
	
	.jga-wrapper-home-services h3{
		margin: 1rem 0 0.5rem;
	}
}

//max-width 767px

@media only screen and (max-width: 767px){
	h1{
		font-size: 2rem;
	}
	
	h2{
		font-size: 1.625rem;
	}
	
	h3{
		font-size: 1.375rem;
	}
	
	//Wrappers
	
	.jga-wrapper{
		padding: 40px 0;
	}
	
	.jga-wrapper-grid{
		padding: 7.5px 15px;
	}
	
	//Headers
	
	.jga-page-header-green{
		height: 15vh;
	}
	
	//Content
	
	.jga-col{
		margin: 7.5px 0;
	}
	
	.jga-page-introduction-content div, .jga-home-studio-content div{
		padding: 40px 15px;
	}	
	
	.jga-people-introduction{
		margin: 0 0 40px;
		padding: 0 0 34px;
	}
	
	.jga-solutions-introduction{
		margin: 0 0 40px;
		padding: 0 0 34px;
	}
	
	.jga-people-single, .jga-solutions-single{
		margin: 0 0 40px;
	}
	
	.jga-solutions-single-content h2{
		font-size: 1.375rem;
	}
	
	//Home
	
	.jga-project-carousel-card{
		padding: 15px;
	}
	
	//Footer
	
	.jga-footer-top, .jga-footer-bottom{
		padding: 40px 0;
	}		
}

//max-width 480px

@media only screen and (max-width: 480px){
	html, body, p{
		font-size: 14px;
	}
	
	.jga-project-carousel-card{
		min-width: 50%;
	}
	
	.owl-dots{
		display: none;
	}
}
