header{
	background: $jga-grey;
	color: $jga-light-grey;
	padding: 20px 30px;
}

//Brand

.jga-brand{
	float: left;
}

.jga-brand a{
	display: block;
}

.jga-brand a:hover{
	opacity: 0.75;
	transition: ease 0.5s all;
}

.jga-logo{
	width: auto;
	height: 50px;
	margin: -3px 0 0;
}

//Navigation

.jga-nav{
	float: right;
	font-family: "Gotham A", "Gotham B";
	font-style: normal;
	font-weight: 400;
}

nav{
	margin: 0 auto;
}

nav li{
	margin: 0 1em;
	padding: 10px 0;
}

nav li:last-child{
	margin-right: 0;
}

nav li a{
	color: $jga-light-grey;
	display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
    position: relative;
    overflow: hidden;
}

nav li a:before{
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    right: 100%;
    bottom: 0;
    background: $jga-green;
    height: 2px;
    transition-property: right;
    transition-duration: .3s;
    transition-timing-function: ease-out;	
}

nav li a:hover:before, nav li a:focus:before, nav li a:active:before {
  right: 0;
}

nav .active a{
	color: $jga-green;
}