//Map

.jga-contact-map {
	width: 100%;
	height: 600px;
}

.jga-contact-map img {
   max-width: inherit !important;
}

//Form

.jga-contact-form label{
	width: 100%;
	font-family: "Gotham A", "Gotham B";
	font-style: normal;
	font-weight: 400;
	color: $black;
}

.jga-contact-form input[type="text"]{
	width: 100%;
	padding: 6px 12px;
	color: $grey;
	font-family: 'Overpass', sans-serif;
	border: 1px solid $jga-input-border;
}

.jga-contact-form input[type="email"]{
	width: 100%;
	padding: 6px 12px;
	color: $grey;
	font-family: 'Overpass', sans-serif;
	border: 1px solid $jga-input-border;
}

.jga-contact-form select{
	width: 100%;
	height: 40px;
	background: $white;
	color: $grey;
	font-family: 'Overpass', sans-serif;
	border-color: $jga-input-border;
}

.jga-contact-form textarea{
	width: 100%;
	padding: 6px 12px;
	color: $grey;
	font-family: 'Overpass', sans-serif;
	border-color: $jga-input-border;
}

.jga-contact-form .jga-button{
	border-color: $jga-green;
	color: $jga-green;
	cursor: pointer;
	transition: $jga-transition;
}

.jga-contact-form .jga-button:hover{
	background: $jga-green;
	color: $white;
	transition: $jga-transition;
}

.jga-contact-form .wpcf7-recaptcha{
	margin: 0 0 calc(2rem - 2px);
}

.jga-contact-form div.wpcf7-response-output{
	margin: 2rem 0 0;
	padding: 1rem;
}

.jga-contact-form span.wpcf7-list-item{
	margin: 0;
}

.jga-contact-form span.wpcf7-list-item label{
	font-family: 'Overpass', sans-serif;
	color: $grey;
	font-size: 14px;
}

.jga-contact-form div.wpcf7-mail-sent-ok{
	border: 2px solid $jga-green;
}

span.wpcf7-not-valid-tip{
	margin: 5px 0 0;
}